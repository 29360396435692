import React from 'react';

import { FeaturedImage } from 'api/endpoints/storefront';
import AspectRatio from 'components/AspectRatio';
import Typography from 'components/Typography';
import Video from 'components/Video';
import useWindowSize from 'hooks/useWindowSize';
import { cx } from 'utils';
import makeDevImageSrc from 'utils/makeDevImgSrc';
import styles from './Highlight.module.css';

export type HighlightProps = FeaturedImage;

const textAlignMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Highlight: React.FCWithChildren<HighlightProps> = ({
  headline,
  link_text,
  link_url,
  video_url,
  image_landscape_1920w,
  text_align = 'left',
  btn_color = 'none',
}) => {
  const hasLink = !!link_url;
  const vimeoId = video_url.match(/\d+/)?.[0];
  const { breakpoints } = useWindowSize();

  const Container = hasLink ? 'a' : React.Fragment;

  return (
    <Container className={styles.overlay} href={link_url}>
      <AspectRatio aspectRatio={9 / 16}>
        <section
          className={styles.root}
          style={
            image_landscape_1920w && !vimeoId
              ? { backgroundImage: `url(${makeDevImageSrc(image_landscape_1920w)})` }
              : {}
          }
        >
          {vimeoId && <Video id={vimeoId} title={headline} background className={styles.video} />}
          <div
            style={{ alignItems: textAlignMap[text_align], textAlign: text_align }}
            className={styles.text}
          >
            <div className={styles.textContainer}>
              <Typography variant="big" component="h1" uppercase>
                {headline}
              </Typography>
              {hasLink && (
                <Typography variant="sub" className={cx(styles.resetLetterspacing)}>
                  {link_text}
                </Typography>
              )}
              <div
                className={cx(
                  styles.button,
                  styles[`button-${btn_color}`],
                  styles[`button-${text_align}`],
                )}
              >
                <Typography
                  variant={breakpoints['phone-down'] ? 'sub' : 'mid'}
                  color="inherit"
                  // eslint-disable-next-line css-modules/no-undef-class
                  className={cx(styles['button-text'], styles.resetLetterspacing)}
                >
                  READ MORE
                </Typography>
              </div>
            </div>
          </div>
        </section>
      </AspectRatio>
    </Container>
  );
};

export default Highlight;
