import React from 'react';
import { Field, Form } from 'react-final-form';
import * as Sentry from '@sentry/nextjs';

import { newsletterSignup } from 'api';
import { RequestError } from 'api/utils';
import Grid from 'components/Grid';
import { FF_Input } from 'components/Inputs';
import ScreenReaderOnly from 'components/ScreenReaderOnly';
import Typography from 'components/Typography';
import ArrowSVG from 'icons/arrow.svg';
import { toast } from 'utils';
import styles from './NewsletterPush.module.css';
export type NewsletterPushProps = React.HTMLProps<HTMLDivElement>;

type FormValues = {
  email: string;
};

const NewsletterPush: React.FCWithChildren<NewsletterPushProps> = ({ ...rest }) => {
  const [success, setSuccess] = React.useState(false);
  const onSubmit = async (v: FormValues) => {
    try {
      const { ok } = await newsletterSignup(v);
      if (ok) {
        setSuccess(true);
      }
    } catch (e) {
      if (e instanceof RequestError) {
        Sentry.captureException(e);
        toast();
      }
    }
  };
  return (
    <Grid columns={2} gap="small" {...rest}>
      <div>
        <Typography component="h2" variant="sub">
          NEWSLETTER
        </Typography>
        <Typography variant="small" component="p">
          Subscribe to receive news about Jacksons collection, VIP events and exhibitions.
        </Typography>
      </div>
      {success ? (
        <div>
          <Typography component="h2" variant="sub" block>
            YOU ARE SIGNED UP!
          </Typography>
        </div>
      ) : (
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} className={styles.form}>
              <Field
                data-cy="newsletter-signup"
                render={FF_Input}
                name="email"
                type="email"
                required
                block
                autoComplete="off"
                disabled={submitting}
                placeholder="ENTER YOUR EMAIL"
                className={styles.input}
              />
              <button className={styles.cta} type="submit">
                <ScreenReaderOnly>Signup</ScreenReaderOnly>
                <ArrowSVG height={24} fill="currentColor" />
              </button>
            </form>
          )}
        </Form>
      )}
    </Grid>
  );
};

export default NewsletterPush;
