/* eslint-disable css-modules/no-undef-class */
import React, { FunctionComponent } from 'react';
import { NodeTreeItem } from 'djedi-json';
import { GetServerSideProps, NextPage } from 'next';
import Link from 'next/link';
import { EXHIBITION, EXHIBITIONS, STORIES, STORY } from 'routes';

import { getExhibitionNode, getFeaturedImages, getProductList } from 'api';
import { ExhibitionVariant } from 'api/endpoints/cms';
import { FeaturedImage } from 'api/endpoints/storefront';
import Block from 'components/Block';
import Clickable from 'components/Clickable';
import Container from 'components/Container';
import ExhibitionCard from 'components/ExhibitionCard';
import Grid from 'components/Grid';
import Highlight from 'components/Highlight';
import MetaDescription from 'components/MetaDescription';
import MetaTitle from 'components/MetaTitle';
import ProductCard from 'components/ProductCard';
import Typography from 'components/Typography';
import Layout from 'containers/Layout';
import Main from 'containers/Main';
import NewsletterPush from 'containers/NewsletterPush';
import InlineArrowSVG from 'icons/inline-arrow.svg';
import styles from 'styles/pages/Home.module.css';
import { Product } from 'types/Product';
import { random, sortExhibitions } from 'utils';
import exhibitionThumbnail from 'utils/exhibitionThumbnail';

interface HomePageProps {
  products: Product[];
  exhibitions: NodeTreeItem[];
  featuredImages: FeaturedImage[];
}

const FeaturedExhibition: FunctionComponent<{ exhibition: NodeTreeItem }> = ({
  exhibition: ex,
}) => {
  const { slug, title, subtitle, variant } = ex.content;

  const exhibition = {
    slug,
    title,
    subtitle,
    image: exhibitionThumbnail(ex) || '',
  };

  const plural = variant === ExhibitionVariant.EXHIBITION ? 'exhibitions' : 'stories';

  return (
    <div>
      <Link
        href={{
          pathname: (variant === ExhibitionVariant.EXHIBITION ? EXHIBITION : STORY).pathname,
          query: { slug },
        }}
      >
        <ExhibitionCard exhibition={exhibition} flip />
      </Link>
      <Link
        href={{
          pathname: (variant === ExhibitionVariant.EXHIBITION ? EXHIBITIONS : STORIES).pathname,
        }}
        passHref
        legacyBehavior
      >
        <Clickable>
          <Typography block variant="sub" align="right" className={styles.exhibitLink}>
            All {plural} <InlineArrowSVG className={styles.exhibitLinkArrow} fill="currentColor" />
          </Typography>
        </Clickable>
      </Link>
    </div>
  );
};

const Home: NextPage<HomePageProps> = props => {
  const { exhibitions, products, featuredImages } = props;

  const featuredImage = random(featuredImages);
  const exhibition = exhibitions.find(
    ({ content }) => content.variant === ExhibitionVariant.EXHIBITION,
  );
  const story = exhibitions.find(({ content }) => content.variant === ExhibitionVariant.STORY);

  return (
    <>
      <MetaTitle />
      <MetaDescription>
        Jackson Design specializes in the best of 20th century Scandinavian and international
        vintage design with main focus on Scandinavian classics.
      </MetaDescription>
      <Layout>
        <Main>
          {/** Highlight */}
          <Container padding="none" className={styles['highlight-container']}>
            {featuredImage && <Highlight {...featuredImage} />}
          </Container>

          {/** Products */}
          <Container>
            <Typography block variant="sub" component="h2" gutter={1}>
              Latest additions
            </Typography>
            <Grid columns={4} gap="regular">
              {products.slice(0, 4).map((p, i) => (
                <Link href={`/products/${p.slug}`} passHref key={i} legacyBehavior>
                  <ProductCard key={i} product={p} />
                </Link>
              ))}
            </Grid>
          </Container>

          {/** Exhibitions */}
          <Container>
            <Grid columns={2} gap="regular">
              {exhibition && <FeaturedExhibition exhibition={exhibition} />}
              {story && <FeaturedExhibition exhibition={story} />}
            </Grid>
          </Container>
        </Main>

        {/** Newsletter */}
        <Block padding="regular" background="darker">
          <Container>
            <NewsletterPush />
          </Container>
        </Block>
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  const [productsResponse, featuredImagesResponse, exhibitionsResponse] = await Promise.all([
    getProductList({ is_featured: true }),
    getFeaturedImages(),
    getExhibitionNode({ featured: true }),
  ]);

  return {
    props: {
      products: productsResponse.data?.results,
      featuredImages: featuredImagesResponse.data,
      exhibitions: sortExhibitions(exhibitionsResponse.data?.map(({ values }) => values) ?? []),
    },
  };
};

export default Home;
