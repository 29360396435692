import React from 'react';

import useEventListener from './useEventListener';

// Copied from postcss.config.js
const breakpointsMap = {
  'small-phone': 320,
  phone: 600,
  'tablet-portrait': 600,
  'tablet-landscape': 900,
  desktop: 1200,
  'big-desktop': 1440,
} as const;

type Breakpoints = {
  [Property in keyof typeof breakpointsMap as `${Property}-up` | `${Property}-down`]: boolean;
};

interface WindowSize {
  width: number;
  height: number;
}

function useWindowSize(): { windowSize: WindowSize; breakpoints: Breakpoints } {
  const [windowSize, setWindowSize] = React.useState<WindowSize>({
    width: 0,
    height: 0,
  });

  const breakpoints = Object.entries(breakpointsMap).reduce<Record<string, boolean>>(
    (obj, [key, num]) => {
      obj[`${key}-down`] = windowSize.width < num;
      obj[`${key}-up`] = windowSize.width > num;

      return obj;
    },
    {},
  ) as Breakpoints;

  const handleSize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEventListener('resize', handleSize);

  // Set size at the first client-side load
  React.useLayoutEffect(() => {
    handleSize();
  }, []);

  return { windowSize, breakpoints };
}

export default useWindowSize;
