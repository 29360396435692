import React from 'react';
import Head from 'next/head';

interface MetaDescriptionProps {
  children: string;
  image?: string;
}

const MetaDescription = ({ children, image }: MetaDescriptionProps) => {
  return (
    <Head>
      <meta name="description" content={children} />
      <meta property="og:description" content={children} />
      {image && <meta property="og:image" content={image} />}
    </Head>
  );
};

export default MetaDescription;
