import React from 'react';

import { VIEMO_BASE_URL } from 'consts';
import { addQueryString, makeQueryString } from 'utils';

export interface VideoProps extends React.HTMLProps<HTMLIFrameElement> {
  className?: string;
  id: string;
  controls?: boolean;
  background?: boolean;
}

/** Render a vimeo video using only the vimeo ID */
const Video: React.FCWithChildren<VideoProps> = ({ background, controls = false, id, ...rest }) => {
  const url = addQueryString(
    VIEMO_BASE_URL + id,
    makeQueryString({
      background: background ? 1 : undefined,
      controls: controls ? 1 : 0,
    }),
  );

  return (
    <iframe
      loading="lazy"
      data-vimeo-defer
      src={url}
      width="100%"
      height="100%"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; fullscreen; picture-in-picture"
      {...rest}
    ></iframe>
  );
};

export default Video;
