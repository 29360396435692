import React from 'react';
import classnames from 'classnames';

import styles from './AspectRatio.module.css';

const AspectRatio: React.FCWithChildren<{ aspectRatio: number; className?: string }> = ({
  aspectRatio,
  children,
  className = '',
}) => {
  return (
    <div
      className={classnames(styles.root, className)}
      style={{ paddingTop: `${aspectRatio * 100}%` }}
    >
      <div className={styles.inner}>{children}</div>
    </div>
  );
};

export default AspectRatio;
