import React from 'react';

import Clickable, { ClickableProps } from 'components/Clickable';
import Image from 'components/Image';
import { Product } from 'types/Product';
import { makeDevImgSrc } from 'utils';

export type ProductCardProps = {
  product: Product;
} & ClickableProps;

/** A simple ProductCard that can behave as a link or button  */
const ProductCard: React.FCWithChildren<ProductCardProps> = ({ product, ...props }) => {
  const { name, browse_thumb, browse_thumb_x2 } = product;

  return (
    <Clickable {...props}>
      <Image
        src={makeDevImgSrc(browse_thumb)}
        srcSet={`${makeDevImgSrc(browse_thumb)} 420w, ${makeDevImgSrc(browse_thumb_x2)} 840w`}
        alt={name}
        aspectRatio="letterbox"
      />
    </Clickable>
  );
};

export default ProductCard;
